import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogImproved: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M11.38 8.12L14.06 5.44C14.3 5.2 14.3 4.8 14.06 4.56L11.38 1.88"
        stroke="#4D7B30"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.38 8.12L14.06 5.44C14.3 5.2 14.3 4.8 14.06 4.56L11.38 1.88"
        stroke="#4D7B30"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M14.24 5H9C6.24 5 4 7.24 4 10" stroke="#4D7B30" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M5.76 15H11C13.76 15 16 12.76 16 10" stroke="#4D7B30" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M8.62 11.88L5.94 14.56C5.7 14.8 5.7 15.2 5.94 15.44L8.62 18.12"
        stroke="#4D7B30"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.62 11.88L5.94 14.56C5.7 14.8 5.7 15.2 5.94 15.44L8.62 18.12"
        stroke="#4D7B30"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </IconBase>
  );
};
