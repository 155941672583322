import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogDashboard: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M16.26 4.75H3.75C3.19772 4.75 2.75 5.19772 2.75 5.75V14.25C2.75 14.8023 3.19772 15.25 3.75 15.25H16.26C16.8123 15.25 17.26 14.8023 17.26 14.25V5.75C17.26 5.19772 16.8123 4.75 16.26 4.75Z"
        stroke="#771C86"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M7.25 15V5" stroke="#771C86" strokeWidth="1.5" strokeMiterlimit="10" />
    </IconBase>
  );
};
