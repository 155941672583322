import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogFixed: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M17 8C17 10.76 14.76 13 12 13C11.36 13 10.75 12.87 10.18 12.65L6.41 16.41C5.63 17.19 4.37 17.19 3.59 16.41C2.81 15.63 2.81 14.37 3.59 13.59L7.35 9.82C7.13 9.25 7 8.64 7 8C7 5.24 9.24 3 12 3C12.67 3 13.31 3.13 13.89 3.38V3.66L11.07 6.48L11.59 8.41L13.52 8.93L16.34 6.11H16.62C16.87 6.69 17 7.33 17 8Z"
        stroke="#D65620"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </IconBase>
  );
};
