export const LegalData = [
  {
    title: 'API Terms of Service',
    url: '/legal/api-terms-of-service',
  },
  {
    title: 'Commercial Deposit Account Agreement',
    url: '/legal/commercial-deposit-account-agreement',
  },
  {
    title: 'Privacy Notice',
    url: '/legal/privacy-notice',
  },
  {
    title: 'Privacy Policy',
    url: '/legal/privacy-policy',
  },
  {
    title: 'Notice to Recruiters & Staffing Agencies',
    url: '/legal/notice-to-recruiters-and-staffing-agencies',
  },
  {
    title: 'Sweep Program Network Banks',
    url: '/legal/sweep-program-network-banks',
  },
];
