import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Text } from '~/elements/Text';

export interface VideoProps {
  src: string;
  type: string;
  title: string;
  posterFrame: IGatsbyImageData;
  length: string;
  alt: string;
}

export const VideoModal: React.FC<VideoProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div onClick={openModal} className="blog-card-video">
        <div className="blog-video-play">
          {/* <Text className="font-semibold">
            {props.title}
            <span className="ml-2 font-book">{props.length}</span>
          </Text> */}
        </div>
        <GatsbyImage alt={props.alt} image={props.posterFrame} className="blog-video-poster" />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-[99999] overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="blog-video-modal">
                <div onClick={closeModal} className="blog-video-modal-close" />
                <video controls>
                  <source src={props.src} type={props.type} />
                </video>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
