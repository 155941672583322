import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogNew: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M9.91 3C9.11 8.14 8.15 9.11 3 9.91V10.1C8.14 10.9 9.11 11.86 9.91 17.01H10.1C10.9 11.87 11.86 10.9 17.01 10.1V9.91C11.87 9.11 10.9 8.15 10.1 3H9.91Z"
        stroke="#2F8FC5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </IconBase>
  );
};
